.Toastify {
  position: absolute;
  z-index: 9999999;

  &__toast-icon {
    display: none;
  }

  &__toast {
    padding: 16px;
    padding-right: 20px;
    color: $CGrey-500;
    background-color: rgba(237, 242, 247, 0.9);
    border: 0.5px solid #e2e8f0;
    border-radius: 13px;
    box-shadow: 4px 10px 25px 0 rgba(#718096, 0.12);
    backdrop-filter: blur(50px);
  }

  &__progress-bar {
    height: 2px;
    background-color: darken($CGrey-500, 5%);
  }

  &__toast-container {
    display: inline-block;
    width: auto;
    min-width: 150px;
    max-width: 320px;

    @media (max-width: 768px) {
      min-width: 150px;
      max-width: 280px;
    }
  }

  &__close-button {
    color: currentColor;
  }
}
